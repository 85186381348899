
import { defineComponent } from 'vue'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import Image from 'primevue/image'

export default defineComponent({
  emits: ['nextPage', 'prevPage', 'cancel'],
  props: {
    formData: {
      type: Object
    },
    isEditing: {
      type: Boolean
    }
  },
  components: {
    Button,
    Dropdown,
    Image
  },
  setup (props, { emit }) {
    const next = () => { emit('nextPage', { pageIndex: 2 }) }
    const back = () => { emit('prevPage', { pageIndex: 2 }) }
    const cancel = () => { emit('cancel') }

    return {
      cancel,
      back,
      next
    }
  }
})
